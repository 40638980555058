import React, {useState, useEffect} from 'react'
import firebaseDb from '../config/Fire'
import TableScrollbar from 'react-table-scrollbar';
import ComponentToPrint from './Invoice';
import Invoice from './Invoice';
import { useHistory } from 'react-router-dom';

const Sales = () => {

    var [contactObjects, setContactObjects] = useState({})
    useEffect( () => {
        firebaseDb.database().ref().child('contacts').child('purchase').on('value', snapshot=>{
            if(snapshot.val()!=null)
            setContactObjects({                                                           
                ...snapshot.val()
            })
        })
    },[])
    let purchase = [];
    const componentpush = () => {
        
        firebaseDb.database().ref().child('contacts/purchase').on('value', snapshot=>{
          snapshot.forEach(snap => {
              // snap.val() is the dictionary with all your keys/values from the 'students-list' path
              purchase.push(snap.val());
          });
          this.setState({ purchase: purchase });
        });
        firebaseDb.database().ref().child('contacts/invoice').push(purchase[0])
        firebaseDb.database().ref().child('contacts/sales').push(purchase[0])
    }
    return (
        <>
            <div>
            
                <div className="col-md-20 p-0">
                <h5>Sales</h5>
                </div>
                <div className="col-md-12 p-0">
                    <TableScrollbar rows={9}>
                        <table className="table table-borderless table-stripped">
                            <thead className="thead-light">
                                <th>Date</th>
                                <th>sno</th>
                                <th>Party Name</th>
                                <th>Bags</th>
                                <th>Sack</th>
                                <th>Rate</th>
                                <th>Weight</th>
                                <th>Tot. Value</th>
                                {/* <th>Comm.</th>
                                <th>Rent</th>
                                <th>Thaattu</th>
                                <th>Advance</th> */}
                                <th>Grand Total</th>
                                <th><a href style={{
                                float: '',
                                flexDirection: 'column',
                                }}>Action</a></th>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(contactObjects).map(id => {
                                        return <tr key={id}>
                                            <td>{contactObjects[id].Date}</td>
                                            <td>{contactObjects[id].sno}</td>
                                            <td>{contactObjects[id].PartyName}</td>
                                            <td>{contactObjects[id].Bags}</td>
                                            <td>{contactObjects[id].Sack}</td>
                                            <td>{contactObjects[id].Rate}</td>
                                            <td>{contactObjects[id].Weight}</td>
                                            <td>{contactObjects[id].Totalvalue}</td>
                                            {/* <td>{contactObjects[id].Commission}</td>
                                            <td>{contactObjects[id].Rent}</td>
                                            <td>{contactObjects[id].Thaattu}</td>
                                            <td>{contactObjects[id].Advance}</td> */}
                                            <td>{contactObjects[id].GrandTotal}</td>
                                            <td>
                                         
                                            <button className="btn btn-save btn-sep" disabled = "21"
                                                > printed                                
                                             </button>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </TableScrollbar>
                </div>
            </div> 
           
        </>
    );
}
export default Sales;