import React, { useState, useEffect } from "react";
import firebaseDb from "../config/Fire";
import TableScrollbar from "react-table-scrollbar";
import ComponentToPrint from "./Bill";
import Sales from "./Sales";
import Print from "./print";
import { useHistory } from "react-router-dom";
const Billing = () => {
  var [contactObjects, setContactObjects] = useState({});

  useEffect(() => {
    firebaseDb
      .database()
      .ref()
      .child("contacts")
      .child("purchase")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setContactObjects({
            ...snapshot.val(),
          });
      });
  }, []);
  // let userRef = this.database.ref('contacts/' + 'purchase' + values.id);
  var purchaseKey = [];
  const sendToCustomer = () => {
    var purchaseRef = firebaseDb.database().ref("/contacts/purchase");

    purchaseRef.once("value", function (snapshot) {
      // vat tutorials = [];

      snapshot.forEach(function (childSnapshot) {
        purchaseKey = childSnapshot.key;
        // var data = childSnapshot.val();
        // ...
      });
      firebaseDb
        .database()
        .ref()
        .child("contacts")
        .child("purchase")
        .child(purchaseKey)
        .update(
          // {'CustomerName': values.CustomerName},
          // initialFieldValues,

          (err) => {
            console.log(err);
            alert("Sent to Customer successfully!");
          }
        );
    });
  };
  let purchase = [];
  const componentpush = () => {
    var purchaseId = "";
    firebaseDb
      .database()
      .ref()
      .child("contacts/salesCart")
      .on("value", (snapshot) => {
        snapshot.forEach((snap) => {
          // snap.val() is the dictionary with all your keys/values from the 'students-list' path
          purchase.push(snap.val());
        });
        //   this.setState({ purchase: purchase });
        snapshot.forEach(function (childSnapshot) {
          purchaseId = childSnapshot.key;
          // var data = childSnapshot.val();
          // ...
        });
      });
    // firebaseDb.database().ref().child('contacts/invoice').push(purchase[0])
    // firebaseDb.database().ref().child('contacts/sales').push(purchase[0])

    firebaseDb
      .database()
      .ref()
      .child("contacts/salesCart")
      .child(purchaseId)
      .update({ printStatus: "Printing" });
    // firebaseDb.database().ref().child('contacts/salesPrint').push(purchase[0])
    // firebaseDb.database().ref().child('contacts/salesCart').child(purchaseId).remove(0)
  };

  return (
    <>
      <div>
        <div className="col-md-20 ">{/* <h5>Cart</h5> */}</div>
        <div className="col-md-10 offset-md-1">
          <TableScrollbar rows={4}>
            <table className="table table-borderless table-stripped p-0">
              <thead className="thead-light">
                <th>Date</th>
                <th>sno</th>
                <th>Party Name</th>
                <th>Bags</th>
                <th>Sack</th>
                <th>Rate</th>
                <th>Weight</th>
                <th>Tot. Value</th>
                <th>Grand Total</th>
                <th>
                  <a
                    href
                    style={{
                      float: "",
                      flexDirection: "column",
                    }}
                  >
                    Action
                  </a>
                </th>
              </thead>
              <tbody>
                {Object.keys(contactObjects).map((id) => {
                  return (
                    <tr key={id}>
                      <td>{contactObjects[id].Date}</td>
                      <td>{contactObjects[id].sno}</td>
                      <td>{contactObjects[id].PartyName}</td>
                      <td>{contactObjects[id].Bags}</td>
                      <td>{contactObjects[id].Sack}</td>
                      <td>{contactObjects[id].Rate}</td>
                      <td>{contactObjects[id].Weight}</td>
                      <td>{contactObjects[id].Totalvalue}</td>
                      <td>{contactObjects[id].GrandTotal}</td>
                      <td>
                        <button
                          className="btn btn-save btn-sep"
                          onClick={componentpush}
                        >
                          Print
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TableScrollbar>
        </div>

        {/* <div className="col-md-20 p-0">
                <h5>Invoice</h5>
                </div>

                <ComponentToPrint/> */}
      </div>
    </>
  );
};
export default Billing;