import React, { useState, useEffect } from "react";
import ContactForm from "./ContactForm";
import firebaseDb from "../config/Fire";
import TableScrollbar from "react-table-scrollbar";
import ReactToPrint from "react-to-print";
const Contacts = () => {
  var [contactObjects, setContactObjects] = useState({});
  useEffect(() => {
    firebaseDb
      .database()
      .ref()
      .child("contacts")
      .child("purchase")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setContactObjects({
            ...snapshot.val(),
          });
      });
  }, []);
  let purchase = [];
  const componentpush = () => {
    var purchaseId = "";
    firebaseDb
      .database()
      .ref()
      .child("contacts/purchase")
      .on("value", (snapshot) => {
        snapshot.forEach((snap) => {
          purchase.push(snap.val());
        });
        snapshot.forEach(function (childSnapshot) {
          purchaseId = childSnapshot.key;
        });
        if (snapshot.val() === null) {
          firebaseDb
            .database()
            .ref()
            .child("contacts/salesCart")
            .push(purchase[0]);
        }
      });
  };
  const addOrEdit = (obj) => {
    firebaseDb
      .database()
      .ref()
      .child("contacts/purchase")
      .push(obj, (err) => {
        console.log(err);
        alert("Purchase saved successfully!");
      });
  };
  const initialFieldValues = {
    CustomerName: "",
  };
  var [values, setValues] = useState(initialFieldValues);
  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  var purchaseKey = "";
  const sendToCustomer = () => {
    var purchaseRef = firebaseDb.database().ref("/contacts/purchase");
    purchaseRef.once("value", function (snapshot) {
      snapshot.forEach(function (childSnapshot) {
        purchaseKey = childSnapshot.key;
      });
      firebaseDb
        .database()
        .ref()
        .child("contacts/purchase")
        .child(purchaseKey)
        .update(values);
    });
  }
  const handleFormSubmit = (e) => {
    e.preventDefault();
    sendToCustomer();
  };
 
  return (
    <>
      <div className="col-md-15 p-0">
        <ContactForm addOrEdit={addOrEdit} />
      </div>
      <div className="col-md-12 p-0">
        {/* <TableScrollbar rows={9}> */}
          <div className="row">
            {/* <div className="column"> */}
            
              <table
                border="1"
                align="center"
                className="table table-stripped col-md-3 offset-md-2"
              >
                <thead class="thead-light">
                  <tr>
                    <th colSpan="5">INWARD</th>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <th>PartyName</th>
                    <th>TimberType</th>
                    <th>Weight</th>
                    <th>CFT</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(contactObjects).map((id) => {
                    return (
                      <tr key={id}>
                        <td>{contactObjects[id].Date}</td>
                        <td>{contactObjects[id].PartyName}</td>
                        <td>{contactObjects[id].TIMBERTYPE}</td>
                        <td>{contactObjects[id].WEIGHT}</td>
                        <td>{contactObjects[id].CFT}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            {/* </div> */}
            <table
              border="1"
              align="center"
              className="table table-stripped col-md-3 offset-md-2"
            >
              <thead class="thead-light">
                <tr>
                  <th colSpan="2">OUTPUT</th>
                </tr>
                <tr>
                  <th>KILOGRAMS</th>
                  <th>Bags</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(contactObjects).map((id) => {
                  return (
                    <tr key={id}>
                      <td>{contactObjects[id].KILOGRAMS}</td>
                      <td>{contactObjects[id].BAGS}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="row">
            {/* <div className="column"> */}
              <table
                border="1"
                align="center"
                className="table table-stripped col-md-3 offset-md-2"
              >
                <thead class="thead-light">
                  <tr>
                    <th colSpan="4">PRODUCTION</th>
                  </tr>
                  <tr>
                    <th>KAADI</th>
                    <th>TimberType</th>
                    <th>Size</th>
                    <th>Bags</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(contactObjects).map((id) => {
                    return (
                      <tr key={id}>
                        <td>{contactObjects[id].KAADI}</td>
                        <td>{contactObjects[id].TimberType}</td>
                        <td>{contactObjects[id].SIZE}</td>
                        <td>{contactObjects[id].Bags}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            {/* </div> */}
            <table
              border="1"
              align="center"
              className="table table-stripped col-md-3 offset-md-2"
            >
              <thead class="thead-light">
                <tr>
                  <th colSpan="4" >Sales</th>
                </tr>
                <tr>
                  <th>CustomerName</th>
                  <th>SPLINTS</th>
                  <th>Weight</th>
                  <th>TOTALBAGS</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(contactObjects).map((id) => {
                  return (
                    <tr key={id}>
                      <td>{contactObjects[id].CustomerName}</td>
                      <td>{contactObjects[id].SPLINTS}</td>
                      <td>{contactObjects[id].Weight}</td>
                      <td>{contactObjects[id].TOTALBAGS}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        {/* </TableScrollbar> */}
      </div>
    </>
  );
};
export default Contacts;
