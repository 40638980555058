import React, { useState } from "react";
import "../css/form.css";
import firebaseDb from "../config/Fire";
// import Contacts from "../components/Contacts";
const ContactForm = (props) => {
  const initialFieldValues = {
    Date: "",
    PartyName: " ",
    TimberType: "",
    Weight: "",
    CFT: "",
    BAGS: "",
    Bags: "",
    CustomerName: "",
    KAADI: "",
    KILOGRAMS: "",
    SIZE: "",
    SPLINTS: "",
    TIMBERTYPE: "",
    WEIGHT: "",
    TOTALBAGS: "",
  };
  var [values, setValues] = useState(initialFieldValues);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  var totalBags = [];
  firebaseDb
    .database()
    .ref()
    .child("contacts/totalBags")
    .on("value", (snapshot) => {
      snapshot.forEach((snapshot) => {
        totalBags.push(snapshot.val());
      });
    });
  const handleFormSubmit = (e) => {
    e.preventDefault();
    e.target.reset();
    props.addOrEdit(values);
  };
  if (values.TIMBERTYPE === "Kmatti") {
    values.CFT = (values.WEIGHT * 33) / 1000;
  } else {
    values.CFT = (values.WEIGHT * 28) / 100;
  }
  const Calc = values.CFT * 10;
  const Bag = Calc / 45;
  const Ctot = values.CFT;
  return (
    <form className="form" autoComplete="off" onSubmit={handleFormSubmit}>
      <meta charset="UTF-8" />
      <div>
        <h5>
          <u>INWARD:</u>
        </h5>
        <div className="form-row">
          <div className="form-group input-group col-md-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="fas fa-calendar"></i>
              </div>
            </div>
            <input
              id="fname"
              className="form-control"
              placeholder=" தேதி"
              type="date"
              name="Date"
              Date={values.Date}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group input-group col-md-3">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="fas fa-user"></i>
              </div>
            </div>
            <input
              autoComplete="on"
              className="form-control"
              placeholder=" Party பெயர்"
              type="text"
              name="PartyName"
              PartyName={values.PartyName}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group input-group col-md-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i class="fas fa-luggage-cart"></i>
              </div>
            </div>
            <input
              className="form-control"
              placeholder=" TIMBERTYPE"
              type="string"
              name="TIMBERTYPE"
              TIMBERTYPE={values.TIMBERTYPE}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group input-group col-md-1">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i class="fas fa-shopping-bag"></i>
              </div>
            </div>
            <input
              className="form-control"
              placeholder=" Weight(Kgs)"
              type="number"
              name="WEIGHT"
              WEIGHT={values.WEIGHT}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group input-group col-md-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i class="fas fa-rupee-sign"></i>
              </div>
            </div>
            <input
              autoComplete="on"
              className="form-control"
              placeholder=" CFT"
              type="number"
              name="CFT"
              value={Ctot}
              CFT={(values.CFT = Ctot)}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div>
        <h5>
          <u>OUTPUT</u>
        </h5>
        <div className="form-row">
          <div className="form-group input-group col-md-3">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i class="fas fa-rupee-sign"></i>
              </div>
            </div>
            <input
              autoComplete="on"
              className="form-control"
              placeholder=" KILOGRAMS"
              type="number"
              name="KILOGRAMS"
              KILOGRAMS={(values.KILOGRAMS = Calc)}
              value={Calc}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group input-group col-md-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="fas fa-balance-scale"></i>
              </div>
            </div>
            <input
              className="form-control"
              placeholder=" Bags"
              type="number"
              name="BAGS"
              BAGS={(values.BAGS = Bag)}
              onChange={handleInputChange}
              value={Bag}
            />
          </div>
        </div>
      </div>
      <div>
        <h5>
          <u>PRODUCTION:</u>
        </h5>
        <div className="form-row">
          <div className="form-group input-group col-md-3">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="fas fa-percent"></i>
              </div>
            </div>
            <input
              className="form-control"
              placeholder="KAADI"
              type="number"
              name="KAADI"
              KAADI={(values.KAADI = Bag)}
              value={Bag}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group input-group col-md-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="fas fa-money-bill-wave"></i>
              </div>
            </div>
            <input
              className="form-control"
              placeholder=" TimberType"
              name="TimberType"
              TimberType={values.TimberType}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group input-group col-md-3">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="fas fa-truck-loading"></i>
              </div>
            </div>
            <input
              autoComplete="on"
              className="form-control"
              placeholder=" SIZE"
              type="number"
              name="SIZE"
              SIZE={values.SIZE}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group input-group col-md-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="fas fa-suitcase-rolling"></i>
              </div>
            </div>
            <input
              className="form-control"
              placeholder=" BAGS"
              type="number"
              name="Bags"
              Bags={values.Bags}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      <h5>
        <u>SALES</u>
      </h5>
      <div className="form-row">
        <div className="form-group input-group col-md-3">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <i className="fas fa-cash-register"></i>
            </div>
          </div>
          <input
            autoComplete="on"
            className="form-control"
            placeholder=" Customer பெயர்"
            type="text"
            name="CustomerName"
            CustomerName={values.CustomerName}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group input-group col-md-3">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <i class="fas fa-people-carry"></i>
            </div>
          </div>
          <input
            autoComplete="on"
            className="form-control"
            placeholder=" SPLINTS"
            type="number"
            name="SPLINTS"
            SPLINTS={values.SPLINTS}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group input-group col-md-2">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <i class="fas fa-truck"></i>
            </div>
          </div>
          <input
            className="form-control"
            placeholder="WEIGHT"
            type="number"
            name="Weight"
            Weight={values.Weight}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group input-group col-md-2">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <i class="fas fa-truck"></i>
            </div>
          </div>
          <input
            className="form-control"
            placeholder="TOTALBAGS"
            type="number"
            name="TOTALBAGS"
            Weight={values.TOTALBAGS}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group input-group col-md-2">
          <input type="reset" value="Reset" className="btn btn-reset btn-sep" />
        </div>
        <div className="form-group input-group col-md-3">
          <input type="submit" value="Save" className="btn btn-save btn-sep" />
        </div>
      </div>
    </form>
  );
};
export default ContactForm;
