import React, {useState, useEffect} from 'react'
import ReactToPrint from "react-to-print";
import firebaseDb from '../config/Fire'
import '../css/button.css';

class Bill extends React.Component {
    constructor(props) {
      super(props);
      this.state = {purchase : []}
      }
      
     render() {
      return (
        <div>
          <ReactToPrint
            trigger={() => <button class="btn btn-1 btn-sep"  style={{
              float: 'right',
              marginTop: 5,
              marginRight: 5,
              flexDirection: 'row',
              }}>Print Invoice</button>}
            content={() => this.componentRef}
          />
          <ComponentToPrint ref={el => (this.componentRef = el)} />
        </div>
      );
    }
  }

class ComponentToPrint extends React.Component {

    constructor(props) {
      super(props);
      this.state = {purchase : []}
    }
        
    componentDidMount() {
        firebaseDb.database().ref().child('contacts/invoice').on('value', snapshot=>{
          let purchase = [];
          let purchaseId = [];
          snapshot.forEach(snap => {
              // snap.val() is the dictionary with all your keys/values from the 'students-list' path
              purchase.push(snap.val());
              purchaseId.push(snap.val().id);
          });
          this.setState({ purchase: purchase });
          this.setState({ purchaseId: purchaseId });
        });
    }
    
    render(){
      return (
        <div className="MainDiv">
          <div>
            <header>
              <div class="jumbotron jumbotron-fluid text-center bg-sky">
                <div class="container">
                  <h1 class="display-4 text-center">Invoice</h1>
                </div>
              </div>
              <table class="meta" style={{
                float: 'right',
                marginBottom: 50,
                paddingTop: 30,
                paddingLeft:60,
                paddingRight:60,
                lineHeight: 1.5,
                flexDirection: 'column',
                }}>
                    
                <tr>
                  <th><span contenteditable>Invoice #</span></th>
                  <td><span contenteditable="true">1</span></td>
                </tr>
                <tr>
                  <th><span contenteditable>Date</span></th>
                  <td><span contenteditable="true">January 1, 2021</span></td>
                </tr>
              </table>
              <address contentEditable="true"  style={{
                marginTop: 100,
                flexDirection: 'column',
                }}>
                <p>Jonathan Neal</p>
                <p>101 E. Chapman Ave<br></br>Orange, CA 92866</p>
                <p>(800) 555-1234</p>
              </address>
              {/* <span><img alt="" src="http://www.jonathantneal.com/examples/invoice/logo.png"></img><input type="file" accept="image/*"></input></span> */}
            </header>
    
            <article style={{
              marginBottom: 0,
              paddingTop: 30,
              lineHeight: 1.5,
              flexDirection: 'column',
              }}>
                <th><span>Billing Address</span></th><br></br>
                <address contenteditable="true">
                    <p>Rahul<br></br>c/o Some Company</p>
                </address><br></br>
                
            </article>
          </div>
    
    
          <div className="container">
              <table id="example" class="display table">
                <thead class="thead-dark">
                <tr>
                    <th>Date</th>
                    <th>S. No.</th>
                    <th>Customer Name</th>
                    <th>Bags</th>
                    <th>Sack</th>
                    <th>Magamai</th>
                    <th>Rate</th>
                    <th>Weight</th>
                    <th>Tot. Value</th>
                    <th>Comm.</th>
                    <th>Rent</th>
                    <th>Thaattu</th>
                    <th>Advance</th>
                    <th>Grand Total</th>
                    </tr>
                </thead>
                <tbody>
                  {this.state.purchase.map(data => {
                      return (
                          <tr>     
                          <td><span contenteditable="true">{data.Date}</span></td>
                          <td><span contenteditable="true">{data.sno}</span></td>
                          <td><span contenteditable="true">{data.CustomerName}</span></td>
                          <td><span contenteditable="true">{data.Bags}</span></td>
                          <td><span contenteditable="true">{data.Sack}</span></td>
                          <td><span contenteditable="true">{data.Magamai}</span></td>
                          <td><span contenteditable="true">{data.Rate}</span></td>
                          <td><span contenteditable="true">{data.Weight}</span></td>
                          <td><span contenteditable="true">{data.Totalvalue}</span></td>
                          <td><span contenteditable="true">{data.Commission}</span></td>
                          <td><span contenteditable="true">{data.Rent}</span></td>
                          <td><span contenteditable="true">{data.Thaattu}</span></td>
                          <td><span contenteditable="true">{data.Advance}</span></td>
                          <td><span contenteditable="true">{data.GrandTotal}</span></td>
                          </tr>
                      );
                  })} 
                </tbody>
            </table>
          </div>
        </div>
      );
    }
}

export default Bill;