import React, {Component} from 'react';
import fire from '../config/Fire';
import logo from '../logo.svg';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Contacts from './Contacts';
import Stock from './Stock';
import AddCustomerFb from './AddCustomerFb';
import Billing from './Billing';
import Invoice from './Invoice';
import Purchaseinvoice from './Purchaseinvoice'
import Sales from './Sales';
import Chittai from'./Chittai';
import Report from'./Report';
import Reload from'./Reload';

class Home extends Component {

    constructor(props){
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            fireErrors: '',
            formTitle: 'Login',
            loginBtn: true
        }
    }
    logout = () => {
        fire.auth().signOut();
    }
    render(){
        const displayPosts = (
            <Tabs>
                <TabList>
                <Tab>Home</Tab>
                <Tab>Purchase</Tab>
                <Tab>Stock</Tab>
                <Tab>Sales</Tab>
                <Tab>SalesInvoice</Tab>
                <Tab>Report</Tab>
                {/* <Tab>Billing</Tab> */}
                {/* <Tab style={{
                flexDirection: 'column',
                }}>
                    Add Customer
                </Tab> */}
                {/* <Tab onClick={Reload} style={{
                    background: 'blue',
                    color: 'white',
                    float: 'right',
                    }}>
                    Refresh
                </Tab> */}
                <Tab onClick={this.logout} style={{
                    background: 'maroon',
                    color: 'white',
                    float: 'right',
                    }}>
                    Sign Out
                </Tab>
                </TabList>

                <TabPanel>
                    <div class="jumbotron jumbotron-fluid">
                        <div class="container">
                            <h1 class="display-4 text-center">சிட்டை பட்டியல்</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <Chittai/>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div class="jumbotron jumbotron-fluid-md-3  ">
                        <div class="container">
                            <h1 class="display-4 text-center">PURCHASE ENTRY</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <Contacts/>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div class="jumbotron jumbotron-fluid-md-3">
                            <div class="container">
                            <h1 class="display-4 text-center">STOCK</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                          <Stock/>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                <div class="jumbotron jumbotron-fluid">
                    <div class="container">
                        <h1 class="display-4 text-center">SALES</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                    <Sales/>
                        {/* <Form/> */}
                    </div>
                </div>
                </TabPanel>
                <TabPanel>
                 <div class="jumbotron jumbotron-fluid"> 
                    <div class="container">
                        <h1 class="display-4 text-center">SALES CART</h1>
                    </div> 
                 </div>                                                                                                                                                                                  
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                    <Invoice/>
                    </div>
                </div>
                </TabPanel>
                <TabPanel>
                <div class="jumbotron jumbotron-fluid">
                    <div class="container">
                        <h1 class="display-4 text-center">REPORT</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                    <Report/>
                    </div>
                </div>
                </TabPanel>
            </Tabs>
        );
        return(
            <div>
                {/* <h1>Welcome User</h1> */}
                {displayPosts}
            </div>
            // <div className="row">
            //     <div className="col-md-8 offset-md-1">
            //         <Contacts></Contacts>
            //     </div>
            // </div>
        )
    }
}
export default Home;