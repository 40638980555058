import React from 'react'

const ROOT_URL = 'https://jsonplaceholder.typicode.com';
const url = `${ROOT_URL}/users`;

class Reload extends React.Component {
    state = {
        users: null
    }
    componentDidMount() {
        fetch(url)
            .then(response => response.json())
            .then(users => this.setState({users: users}));
    }
    render() {
        const {users} = this.state;
        if (users) {
            return (
                <ul>
                    {users.map(user => <li>{user.name}</li>)}
                </ul>
            )
        } else {
            return (<h1>Loading ...</h1>)
        }
    }
}

export default Reload;