      import React, { useState, useEffect } from "react";
import ReactToPrint from "react-to-print";
import firebaseDb from "../config/Fire";
import Billing from "./Billing";
// import '../css/button.css';
import "../css/table.css";

class Purchaseinvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = { purchase: [] };
  }
  render() {
    return (
      <div>
        <Billing />
        <ReactToPrint
          trigger={() => (
            <button
              class="btn btn-1 btn-sep offset-md-1"
              style={{
                float: "right-top",
                marginRight: 5,
                flexDirection: "column",
              }}
            >
              Print Invoice
            </button>
          )}
          content={() => this.componentRef}
        />
        <ComponentToPrint ref={(el) => (this.componentRef = el)} />
      </div>
    );
  }
}

const componentRef = () => {
  firebaseDb
    .database()
    .ref()
    .child("contacts/totalValues")
    .on("value", (snapshot) => {
      let totVal = "";
      snapshot.forEach((snap) => {
        // snap.val() is the dictionary with all your keys/values from the 'students-list' path
        totVal.push(snap.val());
      });
      this.setState({ totVal: totVal });
    });
};

var totalBags = [];
firebaseDb
  .database()
  .ref()
  .child("contacts/totalBags")
  .on("value", (snapshot) => {
    snapshot.forEach((snapshot) => {
      totalBags.push(snapshot.val());
    });
  });

var totalSacks = [];
firebaseDb
  .database()
  .ref()
  .child("contacts/totalSacks")
  .on("value", (snapshot) => {
    snapshot.forEach((snapshot) => {
      totalSacks.push(snapshot.val());
    });
  });

var totalComm = [];
firebaseDb
  .database()
  .ref()
  .child("contacts/totalComm")
  .on("value", (snapshot) => {
    snapshot.forEach((snapshot) => {
      totalComm.push(snapshot.val());
    });
  });

var totalMag = [];
firebaseDb
  .database()
  .ref()
  .child("contacts/totalMag")
  .on("value", (snapshot) => {
    snapshot.forEach((snapshot) => {
      totalMag.push(snapshot.val());
    });
  });
var totalGrand = [];
firebaseDb
  .database()
  .ref()
  .child("contacts/totalGrand")
  .on("value", (snapshot) => {
    snapshot.forEach((snapshot) => {
      totalGrand.push(snapshot.val());
    });
  });

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = { purchase: [] };
  }
  componentDidMount() {
    firebaseDb
      .database()
      .ref()
      .child("contacts/purchase")
      .on("value", (snapshot) => {
        let purchase = [];
        let purchaseId = [];
        snapshot.forEach((snap) => {
          // snap.val() is the dictionary with all your keys/values from the 'students-list' path
          purchase.push(snap.val());
          purchaseId.push(snap.val().id);
        });
        this.setState({ purchase: purchase });
        this.setState({ purchaseId: purchaseId });
      });
  }

  // const exp= +(values.Advance)+ +(values.Rent)+ +(values.Thaattu)
  render() {
    return (
      <div class="card">
      <div class="container" align="center">
      <div className="MainDiv">
        <div>
          <header>
            <div class="jumbotron jumbotron-fluid col-md-10 offset-md-1 ">
              <div class="container ">
                <h4 class="display-4 text-center">SKMK Cottons</h4>
              </div>
            </div>
          </header>
        </div>
        <form align="center" method="POST">
          <div className="container col-md-5 p-0">
            <table
              align="center"
              className="table table-borderless table-stripped col-md-10 offset-md-1"
            >
              <thead class="thead-light ">
                {this.state.purchase.map((data) => {
                  return (
                    <div className="container col-md-10 p-3">
                      <tr>
                        <tr>
                          <th>PartyName</th>
                          <td className="col-md-5" contenteditable="true">
                            {data.PartyName}
                          </td>
                          <th>Bags</th>
                          <td className=" col-md-5" contenteditable="true">
                            {data.Bags}
                          </td>
                          <th>Sack</th>
                          <td className="col-md-5" contenteditable="true">
                            {data.Sack}
                          </td>
                        </tr>
                      </tr>
                    </div>
                  );
                })}
              </thead>
            {/* </table>
            <table
              align="center"
              className="table table-borderless table-stripped col-md-7 "
            > */}
              <thead class="thead-light ">
                {this.state.purchase.map((data) => {
                  return (
                    <div className="container col-md-10 p-3">
                      <tr>
                        <tr>
                          <th>Rate</th>
                          <td className="col-md-5" contenteditable="true">
                            {data.Rate}
                          </td>
                          <th>Weight</th>
                          <td className=" col-md-5 " contenteditable="true">
                            {data.Weight}
                          </td>
                          <th>Totalvalue</th>
                          <td className="col-md-5" contenteditable="true">
                            {data.Totalvalue}
                          </td>
                        </tr>
                      </tr>
                    </div>
                  );
                })}
              </thead>
            {/* </table>
            <table
              align="center"
              className="table table-borderless table-stripped col-md-7"
            > */}
              <thead class="thead-light ">
                {this.state.purchase.map((data) => {
                  return (
                    <div className="container col-md-10 p-3">
                      <tr>
                        <tr>
                          <th>Commission</th>
                          <td className="col-md-5" contenteditable="true">
                            {data.Commission}
                          </td>
                          <th>Magamai</th>
                          <td className="col-md-5" contenteditable="true">
                            {data.Magamai}
                          </td>
                          <th>CM Total</th>
                          <td className="col-md-5" contenteditable="true">
                            {+data.Commission + +data.Magamai}
                          </td>
                        </tr>
                      </tr>
                    </div>
                  );
                })}
              </thead>
            {/* </table>
            <table
              align="center"
              className="table table-borderless table-stripped col-md-7"
            > */}
              <thead class="thead-light ">
                {this.state.purchase.map((data) => {
                  return (
                    <div className="container col-md-10 p-3">
                      <tr>
                        <tr>
                          <th>Advance</th>
                          <td className="col-md-5" contenteditable="true">
                            {data.Advance}
                          </td>
                        </tr>
                        <tr>
                          <th>Thattu</th>
                          <td className=" col-md-5" contenteditable="true">
                            {data.Thaattu}
                          </td>
                        </tr>
                        <tr>
                          <th>Rent</th>
                          <td className="col-md-5" contenteditable="true">
                            {data.Rent}
                          </td>
                       
                          <th>ATR Total</th>
                          <td className="col-md-5" contenteditable="true">
                            {+data.Rent + +data.Thaattu + +data.Rent}
                          </td>
                        </tr>
                      </tr>
                    </div>
                  );
                })}
              </thead>
            </table>
            <table
              border="2"
              align="right"
              className="table table-stripped col-md-8  offset-md-3"
            >
              <thead class="thead-light">
                {this.state.purchase.map((data) => {
                  return (
                    <div>
                      <tr>
                        <th className="col-md-3">Grand.Total</th>
                        <td align="right" contenteditable="true">
                          {+data.Totalvalue -
                            data.Rent +
                            -data.Thaattu +
                            -data.Rent}{" "}
                        </td>
                      </tr>
                    </div>
                  );
                })}
              </thead>
            </table>
            {/* <tfoot>
                    <tr>     
                      <td >TOTAL:</td>
                    <td contenteditable="true">{}</td>
                    <td contenteditable="true">{totalBags}</td>
                    <td contenteditable="true">{totalSacks}</td>
                    <td contenteditable="true">{totalComm}</td>
                    <td contenteditable="true">{totalMag}</td>
                    <td contenteditable="true">{}</td>
                    <td contenteditable="true">{totalGrand}</td> 
                    </tr> 
            </tfoot> */}
          </div>
        </form>
      </div>
      </div>
      </div>
    );
  }
}

export default Purchaseinvoice;
