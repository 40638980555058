import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyAwp94VKSuGxWCRbqHfTLsBn1rugnIi22Y",
  authDomain: "sri-thanga-poorni.firebaseapp.com",
  databaseURL: "https://sri-thanga-poorni-default-rtdb.firebaseio.com",
  projectId: "sri-thanga-poorni",
  storageBucket: "sri-thanga-poorni.appspot.com",
  messagingSenderId: "510402119160",
  appId: "1:510402119160:web:38d960535bcc42be81820d",
  measurementId: "G-1MRSQZGQ91"
};

const fire = firebase.initializeApp(config);
const fireDb = fire.database().ref();
export default fire;
// export default fire.database().ref();