import React, { useState, useEffect } from "react";
import firebaseDb from "../config/Fire";
import TableScrollbar from "react-table-scrollbar";
import ComponentToPrint from "./Bill";
import Select from "react-select";
import "../css/card.css";

const Chittai = (props) => {
  var [contactObjects, setContactObjects] = useState({});

  useEffect(() => {
    firebaseDb
      .database()
      .ref()
      .child("contacts")
      .child("purchase")
      .on("value", (snapshot) => {
        if (snapshot.val() != null)
          setContactObjects({
            ...snapshot.val(),
          });
      });
  }, []);

  // class Sumtotal  {
  //     constructor(props){
  //         super(props)
  //         {
  //             this.state ={
  //                 value: [],   //Initializing an array
  //                 SumValue: '',
  //                 default: false,
  //             }
  //         }

  // const ref = firebaseDb.database().ref('contacts');
  // ref.once('purchase').then((snapshot) => {
  //     snapshot.forEach(function(childSnapshot) {
  //         ref.once(purchaseKey).then((childSnapshot))
  //         purchaseKey = childSnapshot.key;
  //         this.setState({ categories: snapshot.val() });
  //     });
  // });

  // let userRef = this.database.ref('contacts/' + 'purchase' + values.id);
  // componentDidMount() {
  //     {this.state.value && this.state.value.map(function (value, Bags) {
  //         return <Row key={Bags} item={value}></Row>
  //     })
  // }
  // }

  var totalBags = [];
  firebaseDb
    .database()
    .ref()
    .child("contacts/totalBags")
    .on("value", (snapshot) => {
      snapshot.forEach((snapshot) => {
        totalBags.push(snapshot.val());
      });
    });

  var totalSacks = [];
  firebaseDb
    .database()
    .ref()
    .child("contacts/totalSacks")
    .on("value", (snapshot) => {
      snapshot.forEach((snapshot) => {
        totalSacks.push(snapshot.val());
      });
    });

  var totalComm = [];
  firebaseDb
    .database()
    .ref()
    .child("contacts/totalComm")
    .on("value", (snapshot) => {
      snapshot.forEach((snapshot) => {
        totalComm.push(snapshot.val());
      });
    });

  var totalMag = [];
  firebaseDb
    .database()
    .ref()
    .child("contacts/totalMag")
    .on("value", (snapshot) => {
      snapshot.forEach((snapshot) => {
        totalMag.push(snapshot.val());
      });
    });
  var totalGrand = [];
  firebaseDb
    .database()
    .ref()
    .child("contacts/totalGrand")
    .on("value", (snapshot) => {
      snapshot.forEach((snapshot) => {
        totalGrand.push(snapshot.val());
      });
    });

  // var bagsTotal=[];
  // const  totalBags= () =>{
  //     return firebaseDb.database().ref('contacts/totalBags').once('bagsTotal').then(function(snapshot){
  //         snapshot.forEach(function(childSnapshot) {
  //            setSelectedValue({
  //             ...childSnapshot.val(bagsTotal)
  //         })
  //         });
  //         if (totalBags===bagsTotal)
  //         console.log("item found");
  //         return bagsTotal;
  //     });
  // }

  // const componentWillMount = () => {
  //     this.setState({
  //         dataSource : this.List()
  //     })
  //     console.log("items: " + this.state.dataSource);
  // }

  // set value for default selection
  // const [selectedValue, setSelectedValue] = useState(1);

  // handle onChange event of the dropdown
  // const handleChange = e => {
  //   setSelectedValue(e.label);
  // }

  const Contacts = firebaseDb.database().ref("Contacts");
  Contacts.child("adathuCoolie")
    .once("value")
    .then((data) => {
      if (data.val() !== null) {
        console.log(data.val());
      }
    });

  const initialFieldValues = {
    adathuCoolie: "",
    Total: "",
  };

  var [values, setValues] = useState(initialFieldValues);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  var totalBags = [];
  firebaseDb
    .database()
    .ref()
    .child("contacts/totalBags")
    .on("value", (snapshot) => {
      snapshot.forEach((snapshot) => {
        totalBags.push(snapshot.val());
      });
    });
  const componentupdate = () => {
    var bagsTotal = Number(totalBags) + Number(values.Bags);
    firebaseDb
      .database()
      .ref()
      .child("contacts/totalBags")
      .update({ bagsTotal: bagsTotal });
  };

  var totalCoolie = [];
  firebaseDb
    .database()
    .ref()
    .child("contacts/totalCoolie")
    .on("value", (snapshot) => {
      snapshot.forEach((snapshot) => {
        totalCoolie.push(snapshot.val());
      });
    });
  const Ctotal = () => {
    var coolietotal = Number(values.Vandicoolie);
    firebaseDb
      .database()
      .ref()
      .child("contacts/totalCoolie")
      .update({ coolietotal: coolietotal });
  };

  var totalAdathu = [];
  firebaseDb
    .database()
    .ref()
    .child("contacts/totalAdathu")
    .on("value", (snapshot) => {
      snapshot.forEach((snapshot) => {
        totalAdathu.push(snapshot.val());
      });
    });
  const Atotal = () => {
    var adathutotal = Number(values.Adathucoolie);
    firebaseDb
      .database()
      .ref()
      .child("contacts/totalAdathu")
      .update({ adathutotal: adathutotal });
  };

  var finalTotal = [];
  firebaseDb
    .database()
    .ref()
    .child("contacts/total")
    .on("value", (snapshot) => {
      snapshot.forEach((snapshot) => {
        finalTotal.push(snapshot.val());
      });
    });
  const Ftotal = () => {
    var tTotal = Number(values.Total);
    firebaseDb
      .database()
      .ref()
      .child("contacts/total")
      .update({ tTotal: Grandtotal });
  };

  var totalGrand = [];
  firebaseDb
    .database()
    .ref()
    .child("contacts/totalGrand")
    .on("value", (snapshot) => {
      snapshot.forEach((snapshot) => {
        totalGrand.push(snapshot.val());
      });
    });
  const Gtotal = () => {
    var grandtotal = Number(totalGrand) + Number(values.GrandTotal);
    firebaseDb
      .database()
      .ref()
      .child("contacts/totalGrand")
      .update({ grandtotal: grandtotal });
  };

  var totalComm = [];
  firebaseDb
    .database()
    .ref()
    .child("contacts/totalComm")
    .on("value", (snapshot) => {
      snapshot.forEach((snapshot) => {
        totalComm.push(snapshot.val());
      });
    });
  const Cptotal = () => {
    var commtotal = Number(totalComm) + Number(values.Commission);
    firebaseDb
      .database()
      .ref()
      .child("contacts/totalComm")
      .update({ commtotal: commtotal });
  };

  var totalMag = [];
  firebaseDb
    .database()
    .ref()
    .child("contacts/totalMag")
    .on("value", (snapshot) => {
      snapshot.forEach((snapshot) => {
        totalMag.push(snapshot.val());
      });
    });
  const Mtotal = () => {
    var magtotal = Number(totalMag) + Number(values.Magamai);
    firebaseDb
      .database()
      .ref()
      .child("contacts/totalMag")
      .update({ magtotal: magtotal });
  };

  var vandicoolie = [];
  firebaseDb
    .database()
    .ref()
    .child("contacts/vandicoolie")
    .on("value", (snapshot) => {
      snapshot.forEach((snapshot) => {
        vandicoolie.push(snapshot.val());
      });
    });
  const COtotal = () => {
    var coolievandi = Number(vandicoolie) + Number(values.vechicleRent);
    firebaseDb
      .database()
      .ref()
      .child("contacts/vandicoolie")
      .update({ coolievandi: coolievandi });
  };

  var totalPurchase = [];
  firebaseDb
    .database()
    .ref()
    .child("contacts/totalPurchase")
    .on("value", (snapshot) => {
      snapshot.forEach((snapshot) => {
        totalPurchase.push(snapshot.val());
      });
    });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    e.target.reset();
    Ctotal();
    Atotal();
    Ftotal();
    Gtotal();
    Cptotal();
    Mtotal();
    COtotal();
  };

  const Purt = +totalGrand + +totalComm + +totalMag + +vandicoolie;
  const Cmt = +totalComm + -vandicoolie;
  const Stv = +totalPurchase + +vandicoolie;
  const Grandtotal =
    values.Adathucoolie * values.Vandicoolie * Number(totalBags);

  return (
    <>
      <div class="box">
        <div class="column">
          <div class="card" align="center">
            <div class="container">
              <h4>PURCHASE VALUE</h4>
              <table className="table table-borderless table-stripped p-0">
                <thead className="thead-light">
                  <th className="thead-dark"></th>
                  <th>sno</th>
                  <th>Party Name</th>
                  <th>Bags</th>
                  <th>Sack</th>
                  <th>Grand Total</th>
                  <th>Commission</th>
                  <th>Magamai</th>
                </thead>
                <tbody>
                  {Object.keys(contactObjects).map((id) => {
                    return (
                      <tr key={id}>
                        <td>{}</td>
                        <td>{contactObjects[id].sno}</td>
                        <td>{contactObjects[id].PartyName}</td>
                        <td>{contactObjects[id].Bags}</td>
                        <td>{contactObjects[id].Sack}</td>
                        <td>{Math.round(contactObjects[id].GrandTotal)}</td>
                        <td>{Math.round(contactObjects[id].Commission)}</td>
                        <td>{contactObjects[id].Magamai}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot className="thead-light">
                  <tr>
                    <td contenteditable="true">{}</td>
                    <td contenteditable="true">{}</td>
                    <td contenteditable="true">
                      <h6>
                        <b>TOTAL: </b>
                      </h6>
                    </td>
                    <td contenteditable="true">{totalBags}</td>
                    <td contenteditable="true">{totalSacks}</td>
                    <td contenteditable="true">{Math.round(totalGrand)}</td>
                    <td contenteditable="true">{Math.round(totalComm)}</td>
                    <td contenteditable="true">{totalMag}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="card">
            <div class="container" align="center">
              <h4>SALES VALUE</h4>
            </div>
            <table className="table table-borderless table-stripped p-0 ">
              <thead className="thead-light">
                <th></th>
                <th>Bags</th>
                <th>Sack</th>
                <th>vandicoolie</th>
                <th>Tot. Value</th>
              </thead>
              <tbody>
                {Object.keys(contactObjects).map((id) => {
                  return (
                    <tr key={id}>
                      <td>{}</td>
                      <td>{contactObjects[id].Bags}</td>
                      <td>{contactObjects[id].Sack}</td>
                      <td>{vandicoolie}</td>
                      <td>{Math.round(contactObjects[id].GrandTotal)}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <span contenteditable="true">{}</span>
                  </td>
                  <td>
                    <span contenteditable="true">{totalBags}</span>
                  </td>
                  <td>
                    <span contenteditable="true">{totalSacks}</span>
                  </td>
                  <td>
                    <span contenteditable="true">{vandicoolie}</span>
                  </td>
                  <td>
                    <span contenteditable="true">{Math.round(Stv)}</span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div class="card">
            <div class="container" align="center">
              <h4>TOTAL PURCHASE VALUE</h4>
            </div>
            <table className="table table-borderless table-stripped p-0">
              <thead className="thead-light">
                <th>Grand Total</th>
                <th>Commission</th>
                <th>Magamai</th>
                <th>Vandicoolie</th>
                <th>Total</th>
              </thead>
              <tfoot className="thead-light">
                <tr>
                  <td contenteditable="true">{Math.round(totalGrand)}</td>
                  <td contenteditable="true">{Math.round(totalComm)}</td>
                  <td contenteditable="true">{totalMag}</td>
                  <td contenteditable="true">{vandicoolie}</td>
                  <td contenteditable="true">{Math.round(Purt)}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="column">
            <div class="card">
              <div class="container" align="center">
                <h4>4% COMMISSION</h4>
              </div>
              <table className="table table-borderless table-stripped p-0">
                <thead className="thead-light">
                  <th className="thead-dark"></th>
                  <th>Commission</th>
                  <th>Vandi Coolie</th>
                  <th>4%</th>
                </thead>
                <tfoot className="thead-light">
                  <tr>
                    <td></td>
                    <td contenteditable="true">{Math.round(totalComm)}</td>
                    <td contenteditable="true">{vandicoolie}</td>
                    <td contenteditable="true">{Math.round(Cmt)}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chittai;
