import React, { useState, useEffect } from "react";
import ReactToPrint from "react-to-print";
import firebaseDb from "../config/Fire";
import Billing from "./Billing";
import "../css/button.css";

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = { purchase: [] };
  }
  render() {
    return (
      <div>
        <div className="col-md-5 offset-md-1 form">
          <h3>
            <u>SEARCH</u>
          </h3>
        </div>
        <form className="form col-md-5 offset-md-1" autoComplete="off">
          <meta charset="UTF-8" />
          <div className="form-row">
            <div className="form-group input-group col-md-14">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="fas fa-calendar"></i>
                  {/* <label for="fname"><i class="fa fa-user"></i> Date</label> */}
                </div>
              </div>

              <input
                id="fname"
                className="form-control"
                placeholder=" தேதி"
                type="date"
                name="Date"
                // Date={values.Date}
                // onChange={handleInputChange}
              />
            </div>

            <div className="form-group input-group col-md-14">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="fas fa-user"></i>
                </div>
              </div>

              <input
                autoComplete="on"
                className="form-control"
                placeholder=" Party Name"
                type="text"
                name="PartyName"
                // PartyName={values.PartyName}
                // onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group input-group col-md-4">
              <input
                type="reset"
                value="Reset"
                className="btn btn-reset btn-sep"
              />
            </div>
            <div className="form-group input-group col-md-8">
              <input
                type="submit"
                value="Search"
                className="btn btn-save btn-sep"
              />
            </div>
          </div>
        </form>

        <ReactToPrint
          trigger={() => (
            <button
              class="btn btn-1 btn-sep offset-md-1"
              style={{
                float: "right-top",
                marginRight: 5,
                flexDirection: "column",
              }}
            >
              Print Report
            </button>
          )}
          content={() => this.componentRef}
        />
        <ComponentToPrint ref={(el) => (this.componentRef = el)} />
      </div>
    );
  }
}

const componentRef = () => {
  firebaseDb
    .database()
    .ref()
    .child("contacts/totalValues")
    .on("value", (snapshot) => {
      let totVal = "";
      snapshot.forEach((snap) => {
        // snap.val() is the dictionary with all your keys/values from the 'students-list' path
        totVal.push(snap.val());
      });
      this.setState({ totVal: totVal });
    });
};

var totalBags = [];
firebaseDb
  .database()
  .ref()
  .child("contacts/totalBags")
  .on("value", (snapshot) => {
    snapshot.forEach((snapshot) => {
      totalBags.push(snapshot.val());
    });
  });

var totalSacks = [];
firebaseDb
  .database()
  .ref()
  .child("contacts/totalSacks")
  .on("value", (snapshot) => {
    snapshot.forEach((snapshot) => {
      totalSacks.push(snapshot.val());
    });
  });

var totalComm = [];
firebaseDb
  .database()
  .ref()
  .child("contacts/totalComm")
  .on("value", (snapshot) => {
    snapshot.forEach((snapshot) => {
      totalComm.push(snapshot.val());
    });
  });

var totalMag = [];
firebaseDb
  .database()
  .ref()
  .child("contacts/totalMag")
  .on("value", (snapshot) => {
    snapshot.forEach((snapshot) => {
      totalMag.push(snapshot.val());
    });
  });
var totalGrand = [];
firebaseDb
  .database()
  .ref()
  .child("contacts/totalGrand")
  .on("value", (snapshot) => {
    snapshot.forEach((snapshot) => {
      totalGrand.push(snapshot.val());
    });
  });
var totalPurchase = [];
firebaseDb
  .database()
  .ref()
  .child("contacts/totalPurchase")
  .on("value", (snapshot) => {
    snapshot.forEach((snapshot) => {
      totalPurchase.push(snapshot.val());
    });
  });

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = { purchase: [] };
  }

  componentDidMount() {
    firebaseDb
      .database()
      .ref()
      .child("contacts/purchase")
      .on("value", (snapshot) => {
        let purchase = [];
        let purchaseId = [];
        snapshot.forEach((snap) => {
          // snap.val() is the dictionary with all your keys/values from the 'students-list' path
          purchase.push(snap.val());
          purchaseId.push(snap.val().id);
        });
        this.setState({ purchase: purchase });
        this.setState({ purchaseId: purchaseId });
      });
  }

  render() {
    return (
      <div className="MainDiv">
        <div>
          <header>
            <div class="jumbotron jumbotron-fluid col-md-10 offset-md-1">
              <div class="container ">
                <h4 class="display-4 text-center">SKMK Cottons Report</h4>
              </div>
            </div>
          </header>
        </div>
        <div className="container">
          <table id="example" class="display table col-md-10 offset-md-1">
            <thead class="thead-dark">
              <tr>
                <th>Description</th>
                <th>Bags</th>
                <th>Sack</th>
                <th>Rent</th>
                <th>Rate</th>
                <th>Weight</th>
                <th>Tot. Value</th>
              </tr>
            </thead>
            <tbody>
              {this.state.purchase.map((data) => {
                return (
                  <tr>
                    <td>
                      <span contenteditable="true">{}</span>
                    </td>
                    <td>
                      <span contenteditable="true">{data.Bags}</span>
                    </td>
                    <td>
                      <span contenteditable="true">{data.Sack}</span>
                    </td>
                    <td>
                      <span contenteditable="true">{}</span>
                    </td>
                    <td>
                      <span contenteditable="true">{data.Rate}</span>
                    </td>
                    <td>
                      <span contenteditable="true">{data.Weight}</span>
                    </td>
                    <td>
                      <span contenteditable="true">{data.Totalvalue}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th>SALES TOTAL</th>
                <td>
                  <span contenteditable="true">{}</span>
                </td>
                <td>
                  <span contenteditable="true">{}</span>
                </td>
                <td>
                  <span contenteditable="true">{}</span>
                </td>
                <td>
                  <span contenteditable="true">{}</span>
                </td>
                <td>
                  <span contenteditable="true">{}</span>
                </td>
                <td>
                  <span contenteditable="true">{totalPurchase}</span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  }
}
export default Report;
