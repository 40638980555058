import React, { useState, useEffect } from "react";
import ReactToPrint from "react-to-print";
import firebaseDb from "../config/Fire";
import Billing from "./Billing";
import "../css/button.css";
import { database } from "firebase";
import { getByDisplayValue } from "@testing-library/react";

let purchase = [];
const componentDelete = () => {
  var purchaseId = "";
  firebaseDb
    .database()
    .ref()
    .child("contacts/salesPrint")
    .on("value", (snapshot) => {
      snapshot.forEach((snap) => {
        // snap.val() is the dictionary with all your keys/values from the 'students-list' path
        purchase.push(snap.val());
      });
      //   this.setState({ purchase: purchase });
      snapshot.forEach(function (childSnapshot) {
        purchaseId = childSnapshot.key;
        // var data = childSnapshot.val();
        // ...
      });
    });
  // firebaseDb.database().ref().child('contacts/invoice').push(purchase[0])
  firebaseDb.database().ref().child("contacts/salesPrint").remove(purchaseId);
  // firebaseDb.database().ref().child('contacts').child('salesCart').child(purchaseId).remove()
};

class invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = { purchase: [] };
  }
  render() {
    return (
      <div>
        <Billing />
        <ReactToPrint
          trigger={() => (
            <button
              class="btn btn-1 btn-sep offset-md-1"
              style={{
                float: "right-top",
                marginTop: 10,
                marginLeft: 132,
                marginRight: 5,
                flexDirection: "row",
              }}
            >
              Print Invoice
            </button>
          )}
          content={() => this.componentRef}
        />
        <button
          class="btn btn-1 btn-sep"
          style={{
            float: "right-top",
            marginTop: 10,
            flexDirection: "row",
          }}
          onClick={componentDelete}
        >
          Done
        </button>
        <ComponentToPrint ref={(el) => (this.componentRef = el)} />
      </div>
    );
  }
}

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = { purchase: [] };
  }
  componentDidMount() {
    firebaseDb
      .database()
      .ref()
      .child("contacts/purchase")
      .once("value", (snapshot) => {
        let purchase = [];
        let purchaseId = [];
        snapshot.forEach((snap) => {
          // snap.val() is the dictionary with all your keys/values from the 'students-list' path

          // const printStatus = firebaseDb.database().ref().child('contacts/salesCart').child(purchaseId).child('printStatus').val()
          purchaseId.push(snap.val().id);
          // if(printStatus)
          purchase.push(snap.val());
        });
        this.setState({ purchase: purchase });
        this.setState({ purchaseId: purchaseId });
      });
  }
  render() {
    return (
      <div className="MainDiv">
        <div>
          <header>
            <div class="jumbotron jumbotron-fluid text-center col-md-10 offset-md-1">
              <div class="container">
                <h1 class="display-4 text-center">Invoice</h1>
              </div>
            </div>
            <div className="container">
              <table
                border="1"
                class="display table col-md-2 offset-md-8"
                style={{
                  float: "left",
                  flexDirection: "column",
                }}
              >
                <tr>
                  <th>
                    <span>Invoice #:</span>
                  </th>
                  <span contenteditable="true">
                    {this.state.purchase.map((data) => (
                      <td contenteditable="true">{data.sno}</td>
                    ))}
                  </span>
                </tr>
                <tr>
                  <th>
                    <span>Date:</span>
                  </th>
                  <span contenteditable="true">
                    {this.state.purchase.map((data) => (
                      <td contenteditable="true">{data.Date}</td>
                    ))}
                  </span>
                </tr>
              </table>
            </div>
          </header>
        </div>

        <div className="container">
          <table
            id="example"
            width="auto"
            height="auto"
            class="display table col-md-15 offset-md-0 p-0"
          >
            <thead class="thead-dark">
              <th>From Address:</th>
              <tr>
                <td>
                  <span contenteditable="true">
                    <pre>SKMK Cottons</pre>
                    <pre>Near Bus Stand,</pre>
                    <pre>Rajapalayam,</pre>
                  </span>
                </td>
              </tr>
              <th>Billing Address:</th>
              <tr>
                {this.state.purchase.map((data) => (
                  <td>
                    <span contenteditable="true">
                      <pre>{data.CustomerName}</pre>
                      <pre>{data.Billingaddress}</pre>
                    </span>
                  </td>
                ))}
              </tr>
              <th>CompanyName:</th>
              <tr>
                {this.state.purchase.map((data) => (
                  <td>
                    <span contenteditable="true">
                      <pre>{data.CompanyName}</pre>
                    </span>
                  </td>
                ))}
              </tr>
              <tr>
                <th>Description</th>
                <th>Bags</th>
                <th>Sack</th>
                <th>Rate</th>
                <th>Weight</th>
                <th>Tot. Value</th>
              </tr>
            </thead>
            <tbody>
              {this.state.purchase.map((data) => {
                return (
                  <tr>
                    <td align="left">
                      <span contenteditable="true">{}</span>
                    </td>
                    <td align="left">
                      <span contenteditable="true">{data.Bags}</span>
                    </td>
                    <td align="left">
                      <span contenteditable="true">{data.Sack}</span>
                    </td>
                    <td align="left">
                      <span contenteditable="true">{data.Rate}</span>
                    </td>
                    <td align="left">
                      <span contenteditable="true">{data.Weight}</span>
                    </td>
                    <td align="left">
                      <span contenteditable="true">{data.Totalvalue}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              {this.state.purchase.map((data) => {
                return (
                  <tr>
                    <td>
                      <span contenteditable="true">{}</span>
                    </td>
                    <td>
                      <span contenteditable="true">{}</span>
                    </td>
                    <td>
                      <span contenteditable="true">{}</span>
                    </td>
                    <td>
                      <span contenteditable="true">{}</span>
                    </td>
                    <td align="left">
                      <span contenteditable="true">
                        <b>Grand Total</b>
                      </span>
                    </td>
                    <td align="left" contenteditable="true">
                      {data.Totalvalue+ +data.vechicleRent}
                    </td>
                  </tr>
                );
              })}
            </tfoot>
            <tfoot>
              {this.state.purchase.map((data) => {
                return (
                  <tr>
                    <td>
                      <span contenteditable="true">{}</span>
                    </td>
                    <td>
                      <span contenteditable="true">{}</span>
                    </td>
                    <td>
                      <span contenteditable="true">{}</span>
                    </td>
                    <td>
                      <span contenteditable="true">{}</span>
                    </td>
                    <td align="left">
                      <span contenteditable="true">
                        <b>Vechicle Rent</b>
                      </span>
                    </td>
                    <td align="left" contenteditable="true">
                      {" "}
                      {data.vechicleRent}
                    </td>
                  </tr>
                );
              })}
            </tfoot>
          </table>
        </div>
      </div>
    );
  }
}
export default invoice;
